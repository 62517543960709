<template>
  <div id="site-list">
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-autocomplete
              v-model="filters.distributorId"
              :placeholder="$t('common.list.selectDistributor')"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="monthlySummaryTableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.name) }}</span>
        </template>

        <template #[`item.type`]="{ item }">
          <span class="text-no-wrap">{{ item.type === 'reward_point_expiry_reminder' ? 'Reminder' : 'Monthly Summary' }}</span>
        </template>

        <!-- distributor -->
        <template #[`item.distributor`]="{ item }">
          <span class="text-no-wrap">{{ item.distributor?.nicename }}</span>
        </template>
        <!-- Active -->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.schedules[0]?.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="updateSchedules(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>{{ item.schedules[0].active ? 'Disable' : 'Enable' }} this template</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteEmailTemplate(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <email-templates-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      template-type="reward_point_monthly_summary"
      @changed="loadData(); aside = false"
    />
  </div>
</template>
<script>
import {
  updateDistributorEmailTemplateSchedules,
} from '@/api/site/site'
import { useDistributor } from '@/composables'
import EmailTemplatesAside from '@/modules/site/views/email-templates/email-templates-resource/EmailTemplatesAside.vue'
import { t } from '@/plugins/i18n'
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiImage,
  mdiMenuDown,
  mdiMenuUp,
  mdiPencilOutline, mdiPlus, mdiRefresh,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useEmailTemplates from './useEmailTemplates'

export default {
  name: 'Expiry',
  components: { EmailTemplatesAside },
  setup() {
    const {
      tableItems, monthlySummaryTableColumns, tableTotal, options, filters, loading, loadData, deleteEmailTemplate,
    } = useEmailTemplates('reward_point_monthly_summary')

    const { distributorOptions } = useDistributor()

    const actions = ['Edit']

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const resource = ref(null)
    const showFilter = ref(true)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })

    const updateSchedules = async item => {
      const request = updateDistributorEmailTemplateSchedules(item.id, {
        schedules: [{
          interval_value: 30,
          active: !item.schedules[0].active,
        }],
      })
      await request
        .then(async res => {
          loadData()
        })
    }

    return {
      loading,
      t,
      icons: {
        mdiPencilOutline,
        mdiDotsVertical,
        mdiImage,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      setAside,
      aside,
      resource,

      monthlySummaryTableColumns,
      tableItems,
      tableTotal,
      options,
      filters,
      headerprops,
      actions,

      loadData,
      deleteEmailTemplate,
      showFilter,
      distributorOptions,
      updateSchedules,
    }
  },

}
</script>
<style scoped lang="scss">

</style>
